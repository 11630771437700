@import url(https://fonts.googleapis.com/css?family=Bebas+Neue);
@import url(https://fonts.googleapis.com/css?family=Tenor+Sans);
@import url(https://fonts.googleapis.com/css?family=Montserrat);
body {
    margin: 0;
    padding: 0;
    height: 100%;
    /*letter-spacing: 0.1em;*/

    font-family: 'tenor open sans-serif', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: bold !important;

}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
    min-height: 100%;
    font-weight: bold !important;
}

#root {
    height: 100%;
}


.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}



.logo {
    margin: 0px 10px 0px 30px;
}

.root {
    background-color: rgba(47, 119, 142, 1) !important;

}

header {
    position: relative;
    z-index: 1;
    background-color: white !important;

}

header::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

footer {
    position: fixed;
    display: flex;
    place-content: center;
    place-items: center;
    bottom: 0;
    width: 100%;
    height: 40px;
    text-align: center;
    line-height: 40px;
    z-index: 10;
}
