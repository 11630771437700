.logo {
    margin: 0px 10px 0px 30px;
}

.root {
    background-color: rgba(47, 119, 142, 1) !important;

}

header {
    position: relative;
    z-index: 1;
    background-color: white !important;

}

header::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

footer {
    position: fixed;
    display: flex;
    place-content: center;
    place-items: center;
    bottom: 0;
    width: 100%;
    height: 40px;
    text-align: center;
    line-height: 40px;
    z-index: 10;
}